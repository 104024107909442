import React, { useState } from "react";
import BlockContent from "@sanity/block-content-to-react";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";
import _ from "lodash";

/* Serializers
============================================================================= */

const BlockRenderer = (props) => {
    const { style = "normal" } = props.node;

    if (style === "h1") {
        return (
            <h1
                className="pb-10 text-5xl lg:pr-56"
                style={{ fontFamily: "Montserrat-Regular" }}
            >
                {props.children}
            </h1>
        );
    }
    if (style === "h2") {
        return (
            <h2
                className="pt-4 pb-8 text-4xl lg:pr-56"
                style={{ fontFamily: "Montserrat-Regular" }}
            >
                {props.children}
            </h2>
        );
    }
    if (style === "h3") {
        return (
            <h3
                className="pb-6 text-3xl lg:pr-56"
                style={{ fontFamily: "Montserrat-Regular" }}
            >
                {props.children}
            </h3>
        );
    }
    if (style === "h4") {
        return (
            <h3
                className="pb-4 text-2xl lg:pr-56"
                style={{ fontFamily: "Montserrat-Regular" }}
            >
                {props.children}
            </h3>
        );
    }
    if (style === "h5") {
        return (
            <h3
                className="pb-2 text-xl lg:pr-56"
                style={{ fontFamily: "Montserrat-Regular" }}
            >
                {props.children}
            </h3>
        );
    }
    if (style === "h6") {
        return (
            <h3
                className="pb-2 text-lg lg:pr-56"
                style={{ fontFamily: "Montserrat-Regular" }}
            >
                {props.children}
            </h3>
        );
    }
    if (style === "normal") {
        return (
            <p
                className="pb-6 text-base leading-loose font-body lg:pr-56"
                style={{ fontFamily: "Montserrat-Regular" }}
            >
                {props.children}
            </p>
        );
    }

    return BlockContent.defaultSerializers.types.block(props);
};

const serializers = {
    types: {
        block: BlockRenderer,
        youtube: ({ node }) => {
            const { url } = node;
            const id = getYouTubeId(url);
            return (
                <div className="container flex items-start justify-start w-full mx-auto">
                    <div className="w-full lg:w-2/3 md:w-4/5">
                        <YouTube
                            videoId={id}
                            containerClassName="youtube-container"
                        />
                    </div>
                </div>
            );
        },
        iframe: ({ node }) => {
            const { url } = node;
            return (
                <div className="container flex items-start justify-start w-full mx-auto">
                    <div className="w-full lg:w-2/3 md:w-4/5">
                        <div className="youtube-container">
                            <iframe
                                src={url}
                                width="560"
                                height="315"
                                scrolling="no"
                                frameborder="0"
                                allowTransparency="true"
                                allowFullScreen="true"
                            ></iframe>
                        </div>
                    </div>
                </div>
            );
        },
        twitter: ({ node }) => {
            return (
                <div dangerouslySetInnerHTML={{ __html: node.embed_code }} />
            );
        },
        image: ({ node }) => {
            return <FadeInImage node={node} />;
        },
        instagram: ({ node }) => {
            return <InstagramEmbed embed={node.embed_code} />;
        },
    },
    marks: {
        strong: (props) => {
            return (
                <strong style={{ fontFamily: "Montserrat-Bold" }}>
                    {props.children}
                </strong>
            );
        },
        em: (props) => {
            return <em>{props.children}</em>;
        },
    },
};

/* Instagram Embed
============================================================================= */

const InstagramEmbed = ({ embed }) => {
    // Config

    // Render
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: embed,
            }}
        />
    );
};

/* Fade In
============================================================================= */

const FadeInImage = ({ node }) => {
    const [loaded, setLoaded] = useState(false);
    const ref = _.split(node.asset._ref, "-");
    return (
        <img
            onLoad={() => setLoaded(true)}
            className={`opacity-100`}
            src={`https://cdn.sanity.io/images/ej9937ak/production/${ref[1]}-${ref[2]}.${ref[3]}`}
        />
    );
};

/* Export
============================================================================= */

export default serializers;
