import React from "react";

/* Facebook
============================================================================= */

const ShareFB = () => {
    // Render
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 318 318"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
            }}
        >
            <g transform="matrix(1,0,0,1,-211105,-115475)">
                <g transform="matrix(8.33333,0,0,8.33333,201249,92561.4)">
                    <g transform="matrix(-1,0,0,1,2403.41,600.861)">
                        <rect
                            x="1182.64"
                            y="2148.81"
                            width="38.127"
                            height="38.127"
                            style={{ fill: "rgb(3,8,20)" }}
                        />
                    </g>
                </g>
                <g transform="matrix(8.33333,0,0,8.33333,201249,92561.4)">
                    <g transform="matrix(1,0,0,1,1202.54,2776.65)">
                        <path
                            d="M0,-15.155L0,-8.346L8.372,-8.346L8.372,-4.866L0,-4.866L0,6.587L-4.048,6.587L-4.048,-15.463C-4.048,-18.93 -1.235,-21.742 2.232,-21.742L9.825,-21.742L9.825,-18.221L3.065,-18.221C1.372,-18.221 0,-16.848 0,-15.155"
                            style={{
                                fill: "rgb(211,200,70)",
                                fillRule: "nonzero",
                            }}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

/* Export
============================================================================= */

export default ShareFB;
