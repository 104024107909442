import React from "react";

/* Linked In
============================================================================= */

const ShareIN = () => {
    // Render
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 318 318"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
            }}
        >
            <g transform="matrix(1,0,0,1,-211798,-115478)">
                <g transform="matrix(8.33333,0,0,8.33333,201249,92561.4)">
                    <g transform="matrix(-1,0,0,1,2569.78,601.526)">
                        <rect
                            x="1265.82"
                            y="2148.48"
                            width="38.127"
                            height="38.128"
                            style={{ fill: "rgb(3,8,20)" }}
                        />
                    </g>
                </g>
                <g transform="matrix(8.33333,0,0,8.33333,201249,92561.4)">
                    <g transform="matrix(1,0,0,1,1275.88,2756.26)">
                        <path
                            d="M0,5.824C-1.608,5.824 -2.912,4.52 -2.912,2.912C-2.912,1.303 -1.608,0 0,0C1.608,0 2.912,1.303 2.912,2.912C2.912,4.52 1.608,5.824 0,5.824"
                            style={{
                                fill: "rgb(211,200,70)",
                                fillRule: "nonzero",
                            }}
                        />
                    </g>
                </g>
                <g transform="matrix(8.33333,0,0,8.33333,201249,92561.4)">
                    <g transform="matrix(1,0,0,1,0,607.725)">
                        <rect
                            x="1273.55"
                            y="2156.49"
                            width="4.676"
                            height="15.915"
                            style={{
                                fill: "rgb(211,200,70)",
                                fillRule: "nonzero",
                            }}
                        />
                        />
                    </g>
                </g>
                <g transform="matrix(8.33333,0,0,8.33333,201249,92561.4)">
                    <g transform="matrix(1,0,0,1,1285.43,2778.61)">
                        <path
                            d="M0,-13.485C1.239,-14.683 2.687,-15.003 4.244,-15.003C8.639,-15.003 11.672,-11.895 11.672,-7.501L11.672,1.518L7.428,1.518L7.428,-7.501C7.428,-9.551 5.765,-11.442 3.714,-11.442C1.663,-11.442 0,-9.551 0,-7.501L0,1.518L-4.243,1.518L-4.243,-14.397L0,-14.397L0,-13.485Z"
                            style={{
                                fill: "rgb(211,200,70)",
                                fillRule: "nonzero",
                            }}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

/* Export
============================================================================= */

export default ShareIN;
