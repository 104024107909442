import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Link, graphql, useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import useBackgroundColor from "../zustand/useBackgroundColor";
import useNewsletter from "../zustand/useNewsletter";
import SEO from "../components/seo";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../components/serializers";
import moment from "moment";
import ShareFB from "../svg/ShareFB";

import ShareIN from "../svg/ShareIN";
import ShareEmail from "../svg/ShareEmail";
import ShareTwitter from "../svg/ShareTwitter";
import Layout from "../layouts";
import axios from "axios";

/* Blog Template
============================================================================= */

const BlogTemplate = ({ pageContext, location }) => {
    // Config
    const { setIsDark } = useBackgroundColor();
    const { blog } = pageContext;

    useEffect(() => {
        setIsDark(true);
    }, []);

    // Render
    return (
        <Layout location={location}>
            <style>
                {`
                @media screen and (max-width: 1024px) {
                    .block-content ul {
                        padding-right: 0;
                    }
                }
                
                .block-content ul {
                    list-style-type: disc;
                    font-family: "Montserrat-Regular", sans-serif;
                    margin-left: 0rem !important;
                }
                
                ul {
                    display: block;
                    list-style-type: disc;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                    padding-inline-start: 40px;
                }
                
                .block-content ul li {
                    padding-bottom: 0.25rem;
                }
                `}
            </style>
            <Helmet>
                <title>{blog.meta_title}</title>
                <meta name="description" content={blog.meta_description} />
                <meta name="image" content={blog.blogImage.asset.url} />
                <meta property="og:title" content={blog.meta_title} />
                <meta property="og:image" content={blog.blogImage.asset.url} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content={blog.blogImage.asset.url} />
                <meta property="og:url" content={location.href} />
                <meta
                    property="og:description"
                    content={blog.meta_description}
                />
                <meta property="og:type" content="article" />
            </Helmet>
            <div
                className="w-full px-5 pt-32 pb-52 md:pt-52 md:px-5"
                style={{ backgroundColor: "#f9f5ed" }}
            >
                <BlogHero blog={blog} />
                <div className="container w-full pt-2 mx-auto md:pt-8">
                    <BlockContent
                        className="w-full block-content"
                        blocks={blog._rawContent}
                        serializers={serializers}
                    />
                </div>
                <BlogFooter
                    location={location}
                    blog={blog}
                    blogs={blog.related}
                    author={blog.blog_author}
                />
            </div>
            <NewsletterSubscribe />
        </Layout>
    );
};

/* Blog Hero
============================================================================= */

const BlogHero = ({ blog }) => {
    // Config

    // Render
    return (
        <>
            <div
                className="hidden w-full mb-20 md:h-104 h-104 md:block"
                id="#anchor-top"
            >
                <div className="container flex flex-row items-center justify-between h-full mx-auto">
                    <div className="flex flex-col items-start justify-between w-full h-full md:w-2/5">
                        <div>
                            <Link
                                to="/thoughts/"
                                className="flex flex-row items-center justify-start"
                            >
                                <div
                                    style={{
                                        width: 0,
                                        height: 0,
                                        borderTop: "10px solid transparent",
                                        borderBottom: "10px solid transparent",
                                        borderRight: "10px solid #d3c846",
                                        transform: "scaleX(1.6) scaleY(0.9)",
                                    }}
                                />
                                <p
                                    className="pl-4 text-sm"
                                    style={{ fontFamily: "Montserrat-Bold" }}
                                >
                                    Back to Thoughts
                                </p>
                            </Link>
                            <div className="pt-6 pr-4">
                                <h1 className="pb-5 text-4xl leading-tight single-blog-heading">
                                    {blog.title}
                                </h1>
                                <h2
                                    className="pb-2 text-lg single-blog-author md:text-xl"
                                    style={{
                                        color: mapTagToColor(blog.tags[0].tag),
                                    }}
                                >
                                    {blog.subtitle}
                                </h2>
                            </div>
                        </div>
                        <div>
                            <h3 className="pb-2 text-lg single-blog-author md:text-xl">
                                By {blog.blog_author.author_name}
                            </h3>
                            <h5 className="italic text-white single-blog-date">
                                {moment(blog._createdAt).format(
                                    "dddd, MMMM Do YYYY"
                                )}
                            </h5>
                        </div>
                    </div>
                    <div className="relative w-full md:w-3/5">
                        <Image
                            fluid={
                                blog.blogImage.asset.localFile.childImageSharp
                                    .fluid
                            }
                            alt={blog.blogImageAlt}
                            className="w-full md:h-104 h-104 "
                        />
                        <div
                            className="flex flex-row items-center justify-start h-6 pl-2 mr-6 single-blog-category w-44"
                            style={{
                                backgroundColor: mapTagToColor(
                                    blog.tags[0].tag
                                ),
                            }}
                        >
                            <h4 className="leading-tight">
                                {blog.tags[0].tag}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block w-full mb-10 md:hidden" id="#anchor-top">
                <div className="container flex flex-col items-center justify-start h-full mx-auto">
                    <div className="flex flex-col items-start w-full start">
                        <Link
                            to="/thoughts/"
                            className="flex flex-row items-center justify-start pb-2 md:pb-0"
                        >
                            <div
                                style={{
                                    width: 0,
                                    height: 0,
                                    borderTop: "10px solid transparent",
                                    borderBottom: "10px solid transparent",
                                    borderRight: "10px solid #d3c846",
                                    transform: "scaleX(1.6) scaleY(0.9)",
                                }}
                            />
                            <p
                                className="pl-4 text-sm"
                                style={{ fontFamily: "Montserrat-Bold" }}
                            >
                                Back to Thoughts
                            </p>
                        </Link>
                        <h2 className="pt-6 pb-4 pr-8 text-3xl leading-tight single-blog-heading md:text-4xl">
                            {blog.title}
                        </h2>
                        <h3
                            className="pb-4 text-lg single-blog-author md:text-xl"
                            style={{ color: mapTagToColor(blog.tags[0].tag) }}
                        >
                            {blog.subtitle}
                        </h3>
                        <Image
                            fluid={
                                blog.blogImage.asset.localFile.childImageSharp
                                    .fluid
                            }
                            className="w-full h-64"
                        />
                        <div className="flex flex-row items-center justify-between w-full pt-4">
                            <h3 className="font-bold single-blog-author">
                                By {blog.blog_author.author_name}
                            </h3>
                            <h5 className="italic text-white single-blog-date">
                                {moment(blog._createdAt).format("MMM Do, YYYY")}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapTagToColor = (tag) => {
    switch (tag) {
        case "Design":
            return "#1BB295";
        case "Agency":
            return "#C69AF4";
        case "Web":
            return "#D86CBF";
        case "Social":
            return "#48B0CC";
        case "AR":
            return "#C94F6F";
        default:
            return "#FF5B22";
    }
};

/* Blog Footer
============================================================================= */

const BlogFooter = ({ blogs, author, blog, location }) => {
    // Config

    // Render
    return (
        <div
            className="container w-full pt-10 mx-auto mt-10"
            style={{ borderTop: "solid 1px black" }}
        >
            <p style={{ fontFamily: "Montserrat-Regular" }}>
                {author.author_bio[0].children[0].text}
            </p>
            <div className="flex flex-col-reverse items-start justify-between w-full pt-12 pb-2 placeholder-teal-600 md:flex-row md:items-center md:pt-12 md:pb-0">
                <a
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                    className="flex-row items-center justify-start hidden pt-12 text-sm cursor-pointer md:pt-0 md:flex"
                    style={{ fontFamily: "Montserrat-Bold" }}
                >
                    <div
                        style={{
                            width: 0,
                            height: 0,
                            borderLeft: "10px solid transparent",
                            borderRight: "10px solid transparent",
                            borderBottom: "10px solid #d3c846",
                            transform: "scaleX(0.9) scaleY(1.5)",
                        }}
                    />
                    <span className="pl-2">Return to Top</span>
                </a>
                <div className="flex flex-col items-start justify-start w-full md:flex-row md:items-center md:w-auto">
                    <div className="flex flex-row items-center justify-start pb-4 md:pb-0">
                        <h2
                            className="text-2xl md:text-5xl"
                            style={{ fontFamily: "Kritik-Bold" }}
                        >
                            Share Article
                        </h2>
                        <div
                            onClick={() => {}}
                            className={`h-10 w-10 pt-1 ml-4 cursor-pointer md:block hidden`}
                        >
                            <svg id="Bold" viewBox="0 0 24 24">
                                <path
                                    style={{
                                        fill: "#d3c846",
                                    }}
                                    d="M21.723,11.28,10.857.3a1.006,1.006,0,0,0-.711-.3H2.989a1,1,0,0,0-.707,1.707l10.08,10.1a.25.25,0,0,1,0,.353L2.285,22.294A1,1,0,0,0,2.994,24h7.16a1,1,0,0,0,.712-.3L21.724,12.686A1,1,0,0,0,21.723,11.28Z"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start md:ml-6 md:pt-2">
                        <div className="w-10 h-10 mr-4">
                            <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ShareFB />
                            </a>
                        </div>
                        <div className="w-10 h-10 mx-4">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://www.linkedin.com/shareArticle?mini=true&url=${location.href}&title=${blog.title}&summary=${blog.subtitle}&source=LinkedIn`}
                            >
                                <ShareIN />
                            </a>
                        </div>
                        <div className="w-10 h-10 mx-4">
                            <a
                                href={`mailto:user@example.com?subject=${blog.title}&body=${blog.subtitle}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ShareEmail />
                            </a>
                        </div>
                        <div className="w-10 h-10 mx-4">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`
https://twitter.com/intent/tweet?url=${location.href}&text=${blog.title}`}
                            >
                                <ShareTwitter />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <RelatedBlogs blogs={blogs} />
            <a
                onClick={() => {
                    window.scrollTo(0, 0);
                }}
                className="flex flex-col items-center justify-start pt-8 text-sm cursor-pointer md:hidden"
                style={{ fontFamily: "Montserrat-Bold" }}
            >
                <div
                    className="mb-2"
                    style={{
                        width: 0,
                        height: 0,
                        borderLeft: "10px solid transparent",
                        borderRight: "10px solid transparent",
                        borderBottom: "10px solid #d3c846",
                        transform: "scaleX(0.9) scaleY(1.5)",
                    }}
                />
                <span className="pl-2">Return to Top</span>
            </a>
        </div>
    );
};

/* Related Blogs
============================================================================= */

const RelatedBlogs = ({ blogs }) => {
    // Config
    const renderBlogItems = () => {
        return blogs.map((blog, i) => {
            const color = mapTagToColor(blog.tags[0].tag);
            return <BlogItem key={i} blog={blog} color={color} />;
        });
    };

    // Render
    if (!blogs || !blogs.length) {
        return null;
    }
    return (
        <div className="container pt-12 mx-auto md:pt-24">
            <h2 className="pb-8 text-4xl" style={{ fontFamily: "Kritik-Bold" }}>
                Related
            </h2>
            <div className="container grid w-full grid-cols-1 gap-12 pb-8 mx-auto lg:grid-cols-3 md:grid-cols-2">
                {renderBlogItems()}
            </div>
        </div>
    );
};

/* Blog Item
============================================================================= */

const BlogItem = ({ blog, color }) => {
    // Config

    // Render
    return (
        <Link
            to={`/blog/${convertToKebabCase(blog.title)}`}
            className="w-full blog-item"
        >
            <div className="relative w-full overflow-hidden h-52">
                <Image
                    fluid={blog.blogImage.asset.localFile.childImageSharp.fluid}
                    className="w-full h-52"
                />
            </div>
            <div className="relative flex flex-col items-start justify-between w-full pr-12 overflow-hidden h-44">
                <h3 className="pt-3 text-lg leading-tight blog-title">
                    {blog.title}
                </h3>
                <h4 className="leading-tight blog-subtitle" style={{ color }}>
                    {blog.subtitle}
                </h4>
                <div className="flex flex-row items-center justify-between w-full">
                    <h5 className="pt-1 text-xs leading-relaxed blog-date font-body">
                        {moment(blog._createdAt).format("MMM D, YYYY")}
                    </h5>
                    <h5 className="pt-1 text-xs leading-relaxed blog-date">
                        By {blog.blog_author.author_name}
                    </h5>
                </div>
                <div
                    className="flex flex-row items-center justify-end h-6 pr-2 mr-6 blog-category w-44"
                    style={{ backgroundColor: color }}
                >
                    <h4 className="leading-tight">{blog.tags[0].tag}</h4>
                </div>
            </div>
        </Link>
    );
};

const convertToKebabCase = (string) => {
    return string
        .replace(/\s+/g, "-")
        .toLowerCase()
        .replace(/[^a-zA-Z0-9-_]/g, "");
};

/* Newsletter Subscribe
============================================================================= */

const NewsletterSubscribe = () => {
    // Config
    const [email, setEmail] = useState("");
    const { setNewsletterOpen } = useNewsletter();

    const onSubmit = async () => {
        await axios.post(
            "https://www.madeinthepile.com/.netlify/functions/newsletter",
            {
                email,
            }
        );
        setEmail("");
        alert("Subscribed!");
    };
    // Render
    return (
        <>
            <div
                id="newsletter-slider"
                className="fixed hidden w-screen h-48 md:block"
            >
                <div id="newsletter-open" className="w-screen h-16">
                    <div className="container flex flex-row items-center justify-start mx-auto">
                        <h2
                            className="py-2 text-3xl"
                            style={{ fontFamily: "Kritik-Bold" }}
                        >
                            SUBSCRIBE TO OUR NEWSLETTER
                        </h2>
                    </div>
                </div>
                <div className="w-screen h-32">
                    <div className="container flex flex-col items-start justify-between w-full h-full pb-8 mx-auto">
                        <p className="w-4/5 pb-2 text-base slide-subheading lg:text-xl">
                            We won't annoy you too much, only once a month in
                            fact!
                        </p>
                        <div className="flex flex-row items-end justify-start w-full">
                            <input
                                className="text-black newsletter-input"
                                placeholder="Email Address"
                                type="email"
                                style={{ color: "black" }}
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button
                                onClick={onSubmit}
                                className="px-8 py-2 ml-8 text-xl"
                                style={{
                                    color: "white",
                                    backgroundColor: "black",
                                    fontFamily: "Montserrat-Bold",
                                }}
                            >
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="fixed bottom-0 w-screen h-12 bg-black md:hidden"
                style={{ zIndex: 1000 }}
                onClick={() => {
                    setNewsletterOpen(true);
                }}
            >
                <div className="container flex flex-row items-center justify-start h-12 mx-auto">
                    <h2
                        className="w-full pl-4 text-lg text-left text-white"
                        style={{ fontFamily: "Kritik-Bold", fontSize: "4vw" }}
                    >
                        SUBSCRIBE TO OUR NEWSLETTER
                    </h2>
                </div>
            </div>
        </>
    );
};

/* Export
============================================================================= */

export default BlogTemplate;
