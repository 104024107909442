import React from "react";

/* Email
============================================================================= */

const ShareEmail = () => {
    // Render
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 318 318"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
            }}
        >
            <g transform="matrix(1,0,0,1,-212491,-115471)">
                <g transform="matrix(8.33333,0,0,8.33333,201249,92561.4)">
                    <g transform="matrix(-1,0,0,1,2736.14,599.818)">
                        <rect
                            x="1349.01"
                            y="2149.33"
                            width="38.127"
                            height="38.128"
                            style={{ fill: "rgb(3,8,20)" }}
                        />
                    </g>
                </g>
                <g transform="matrix(8.33333,0,0,8.33333,201249,92561.4)">
                    <g transform="matrix(1,0,0,1,1379.54,2760.17)">
                        <path
                            d="M0,16.098L-22.935,16.098L-22.935,4.095C-20.946,8.444 -16.562,11.467 -11.467,11.467C-6.373,11.467 -1.988,8.444 0,4.096L0,16.098ZM1.951,-1.934L-24.886,-1.934L-24.886,18.032L1.951,18.032L1.951,-1.934Z"
                            style={{
                                fill: "rgb(211,200,70)",
                                fillRule: "nonzero",
                            }}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

/* Export
============================================================================= */

export default ShareEmail;
