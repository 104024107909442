import React from "react";

/* Twitter
============================================================================= */

const ShareTwitter = () => {
    // Render
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 318 318"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
            }}
        >
            <g transform="matrix(1,0,0,1,-213184,-115475)">
                <g transform="matrix(8.33333,0,0,8.33333,201249,92561.4)">
                    <g transform="matrix(-1,0,0,1,2902.49,600.861)">
                        <rect
                            x="1432.18"
                            y="2148.81"
                            width="38.127"
                            height="38.127"
                            style={{ fill: "rgb(3,8,20)" }}
                        />
                    </g>
                </g>
                <g transform="matrix(8.33333,0,0,8.33333,201249,92561.4)">
                    <g transform="matrix(1,0,0,1,1437.12,2759.55)">
                        <path
                            d="M0,18.377L0,18.265C0.139,18.257 0.278,18.247 0.417,18.242C1.245,18.209 2.08,18.229 2.9,18.132C4.005,18.003 5.072,17.679 6.071,17.191C6.836,16.819 7.559,16.36 8.3,15.94C8.292,15.904 8.284,15.869 8.277,15.834C8.189,15.801 8.105,15.756 8.015,15.737C7.778,15.689 7.536,15.661 7.301,15.606C5.274,15.138 3.881,13.919 3.065,12.02C2.968,11.793 3.024,11.728 3.276,11.728C3.809,11.729 4.34,11.73 4.871,11.713C5.027,11.708 5.183,11.647 5.338,11.61C5.342,11.584 5.345,11.557 5.349,11.53C4.043,11.176 2.951,10.485 2.138,9.418C1.334,8.363 0.858,7.177 0.915,5.781C1.346,5.942 1.719,6.109 2.108,6.219C2.5,6.328 2.898,6.463 3.41,6.386C2.188,5.443 1.398,4.306 1.104,2.857C0.811,1.414 0.96,0.033 1.7,-1.329C2.014,-1.027 2.3,-0.767 2.57,-0.49C3.696,0.658 4.902,1.706 6.309,2.504C7.535,3.201 8.82,3.754 10.183,4.137C11.281,4.446 12.397,4.637 13.533,4.733C13.777,4.755 13.835,4.673 13.802,4.43C13.548,2.557 13.962,0.868 15.267,-0.539C16.248,-1.598 17.448,-2.289 18.908,-2.394C19.748,-2.456 20.594,-2.463 21.423,-2.171C22.229,-1.886 22.936,-1.471 23.577,-0.916C23.721,-0.792 23.928,-0.689 24.112,-0.676C24.316,-0.66 24.537,-0.736 24.734,-0.812C25.583,-1.141 26.425,-1.484 27.271,-1.819C27.358,-1.854 27.455,-1.865 27.593,-1.898C27.142,-0.63 26.314,0.287 25.357,1.124C26.368,1.05 27.296,0.712 28.252,0.441C28.212,0.586 28.164,0.709 28.081,0.798C27.455,1.474 26.881,2.216 26.172,2.79C25.662,3.203 25.535,3.64 25.498,4.231C25.431,5.29 25.348,6.355 25.161,7.397C24.943,8.606 24.532,9.767 24.045,10.898C23.181,12.909 21.963,14.677 20.409,16.214C18.75,17.856 16.818,19.068 14.625,19.865C13.59,20.241 12.521,20.516 11.432,20.647C10.501,20.758 9.555,20.752 8.615,20.785C7.076,20.84 5.574,20.608 4.098,20.182C2.649,19.763 1.295,19.138 0,18.377"
                            style={{
                                fill: "rgb(211,200,70)",
                                fillRule: "nonzero",
                            }}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

/* Export
============================================================================= */

export default ShareTwitter;
